import email from '../assets/email.png';
import api from '../assets/api.png';
import css from '../assets/css.png';
import git from '../assets/git.png';
import html from '../assets/html.png';
import javascript from '../assets/javascript.png';
import node from '../assets/node.png';
import python from '../assets/python.png';
import react from '../assets/react.png';
import sass from '../assets/sass.png';
import sanity from '../assets/sanity.png';
import firebase from '../assets/firebase.png';

import about04 from '../assets/about04.png';

import logo from '../assets/logo.png';

export default {
  email,
  api,
  css,
  git,
  html,
  javascript,
  node,
  python,
  react,
  sass,
  sanity,
  firebase,
  about04,
  logo,
};
